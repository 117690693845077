import React from 'react';
import { Link, graphql } from 'gatsby';

import Image from 'gatsby-image';
import { Tag, Descriptions } from 'antd';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import { rhythm } from '../utils/typography';
import '../styles/meet-us.scss'
import 'antd/dist/antd.css';

const IndexPage = (props) => {
  const { data } = props;

  const siteTitle = props.data.site.siteMetadata.title;
  const ourPigs = [
    {
      name: 'Moppie',
      image: data.meetMop.childImageSharp.fluid,
      description: "Mop will always be pig mom's special bear. She's a calm and graceful pig. \
      Can pigs be graceful? Yes. If Mop were a dog she would be an emotional support animal.",
    },
    {
      name: 'Muffin',
      image: data.meetMuffin.childImageSharp.fluid,
      description: "Every herd needs a queen. Muffin is the big boss of ours. She's a kind ruler \
      but everyone knows to stay out of her way when she's in a bad mood. She's a chunky girl who \
      still shows everyone her baby face when there are treats on the line.",
    },
    {
      name: 'Maple',
      image: data.mapleGreeting.childImageSharp.fluid,
      description: "Maple was the first pig we got after Mop and Muffin lost their sister pancake.\
            She's a spunky kid who's constantly on the lookout for treats. You can't eat an orange\
            without her knowing.",
    },
    {
      name: 'Milo',
      image: data.meetMilo.childImageSharp.fluid,
      description: "She's the newest baby of the family but has quickly become a natural member of the \
          herd. She's got a lot of energy and a brain that is constantly confused by this world. By the way, \
          do you think she looks like a chicken? Because we kind of do. Is that weird? We do. In fact, we call \
          her chicken more than half the time. Do your pigs have any odd nicknames?",
    },
  ];
  return (
    <Layout location={props.location} title={siteTitle}>

      <h1>Our Goal</h1>
      <p>
        Since we got our first guinea pigs in 2019, we have learned a lot about their behavior, needs, and care. We have found that our pigs are friendly and warm our hearts almost in the same way that more traditional pets
        like cats and dogs do. However, guinea pigs are gentle and fragile creatures and need to right environment and care to live their best lives and bring joy to your family. Our goal is to spread this information.
      </p>
      <h1>Our Herd</h1>
      <div>
        {
              ourPigs.map((pig) => (
                <div style={{ textAlign: 'center' }}>
                  <h2 style={{ color: 'pink', marginBottom: 0, padding: 10 }}>{pig.name}</h2>
                  <div className="pig-bio">
                    <Image
                      imgStyle={{ height: 'auto' }}
                      className="pig-image"
                      fluid={pig.image}
                      alt={`Our pig ${pig.name}`}
                    />
                    <div>
                      <p className="pig-bio-text">
                        {pig.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            }
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    mapleGreeting: file(absolutePath: { regex: "/meet-maple.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    meetMop: file(absolutePath: { regex: "/meet-mop.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meetMuffin: file(absolutePath: { regex: "/meet-muffin.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meetMilo: file(absolutePath: { regex: "/meet-milo.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }`;
